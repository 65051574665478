<template>
  <div>
    <app-card>
      <template #body>
        <complex-form :complex="complex" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'complexesList'})">
        {{ $t('btn.back_to_complexes') }}
      </b-button>
      <b-button class="mr-3" variant="danger" v-b-modal.delete-complex-modal>
        {{ $t('btn.delete') }}
      </b-button>
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

    <app-modal
        id="delete-complex-modal"
        :title="$t('label.confirm_action')"
        :ok-text="$t('btn.delete')"
        ok-variant="danger"
        @ok="deleteItem"
    >
      <template #app-modal-body>
        <span class="font-size-lg">{{ $t('modal.delete_complex') }}?</span>
      </template>
    </app-modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import ComplexForm from './components/ComplexForm';

export default {
  components: {
    AppCard,
    AppModal,
    ComplexForm
  },
  data: () => ({
    form: {},
  }),
  beforeMount() {
    this.$store.dispatch('complexesStore/GET', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      complex: 'complexesStore/COMPLEX'
    })
  },
  methods: {
    updateItem() {
      this.$store.dispatch('complexesStore/UPDATE', {id: this.complex.id, data: this.form}).then(() => {
        this.$router.push({name: 'complexesList'});
      });
    },
    deleteItem() {
      this.$store.dispatch('complexesStore/DELETE', this.complex.id).then(() => {
        this.$router.push({name: 'complexesList'});
      });
    }
  }
}
</script>